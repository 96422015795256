<template>
    <div id="walkwaysPanel" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="walkways"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="walkway_walkwaysListSubTitle"></app-sitetitle>
                            <div class="kt-subheader__toolbar">
                                <div class="kt-subheader__wrapper">
                                    <div class="dropdown displayInlineBlock">
                                        <button
                                            class="btn btn-bold btn-brand btn-sm"
                                            type="button"
                                            @click="onClickNeedHelp"
                                        >
                                            {{ $t("common_needHelp") }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="walkways" :siteId="siteId"></app-accesserrorportlet>

                        <div v-if="accessGranted" class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row marginRowLeftAndRightZero">
                                <div class="col-lg-12 alert alert-light alert-elevate" role="alert">
                                    <div class="alert-icon"><i class="flaticon-questions-circular-button kt-font-brand"></i></div>
                                    <div class="alert-text">
                                        <span>{{ $t("walkway_helpText1") }}</span>
                                        <br />
                                        <span>{{ $t("walkway_helpText2") }}</span>
                                        <br />
                                        <span>{{ $t("walkway_helpText3") }}</span>
                                    </div>
                                    <div class="alert-close">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true"><i class="la la-close"></i></span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-5 col-xl-4">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--height-fluid">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("walkway_walkwaysList") }}
                                                </h3>
                                            </div>
                                            <div class="kt-portlet__head-toolbar">
                                                <template v-if="(currentWalkway.state === '' || currentWalkway.state === 'HIGHLIGHT') && siteWalkways && siteWalkways.length !== 0 && !currentSite.isInProduction">
                                                    <a id="createNewWalkwayButton" href="#" @click="onCreate" class="btn btn-brand btn-bold btn-sm btn-icon-h">
                                                        <i class="flaticon2-plus kt-margin-r-5"></i>
                                                        <span id="createNewWalkwayLabel">{{ $t("common_create") }}</span>
                                                    </a>
                                                </template>
                                                <button  v-if="(currentWalkway.state === '' || currentWalkway.state === 'HIGHLIGHT') && siteWalkways && siteWalkways.length !== 0 && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled btn btn-brand btn-bold btn-sm btn-icon-h">
                                                    <i class="flaticon2-plus kt-margin-r-5"></i>
                                                    <span id="createNewWalkwayLabel">{{ $t("common_create") }}</span>
                                                </button>
                                                <template v-if="(currentWalkway.state === 'START_CREATION' || currentWalkway.state === 'FINALIZE_CREATION' || currentWalkway.state === 'START_MEASURE') && siteWalkways.length !== 0 && !currentSite.isInProduction">
                                                    <a id="cancelCreateNewWalkwayButton" href="#" @click="onCancelCreation" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('gtw_cancelAction')" class="walkwayListLink btn btn-sm btn-outline-dark btn-bold">
                                                        <span id="cancelCreateWalkwayLabel">{{ $t("common_cancel") }}</span>
                                                    </a>
                                                </template>
                                                <button  v-if="(currentWalkway.state === 'START_CREATION' || currentWalkway.state === 'FINALIZE_CREATION' || currentWalkway.state === 'START_MEASURE') && siteWalkways.length !== 0 && currentSite.isInProduction" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled walkwayListLink btn btn-sm btn-outline-dark btn-bold">
                                                    <span id="cancelCreateWalkwayLabel">{{ $t("common_cancel") }}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="kt-portlet__body">
                                            <span v-if="siteWalkways && siteWalkways.length == 1" class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge">{{ siteWalkways.length }} {{ $t("walkway_walkway") }} &nbsp;/&nbsp; {{ totalDistance }} m</span>
                                            <span v-else-if="siteWalkways && siteWalkways.length > 1" class="counterTopList kt-badge font500 kt-margin-b-25 kt-badge--info kt-badge--inline list-top-badge">{{ siteWalkways.length }} {{ $t("walkway_walkways") }} &nbsp;/&nbsp; {{ totalDistance }} m</span>

                                            <!--begin emptyList component-->
                                            <app-emptylist targetObjectName="WALKWAY" mainTextKey="walkway_notFound" subTextKey="walkway_notFoundSubLabel" imgName="empty_walkway.svg" v-bind:isDisplayed="isEmptyList" :siteInProduction="siteInProduction" :class="{ fullWidth: true }" @oncreatewalkway="onCreate" @oncancelcreatewalkway="onCancelCreation"></app-emptylist>
                                            <!--end emptyList component-->

                                            <div v-if="siteWalkways && siteWalkways.length !== 0" class="form-group">
                                                <div class="kt-input-icon kt-input-icon--left" style="margin-top: -10px; margin-bottom: -10px;">
                                                    <input v-model="searchTextValue" type="search" class="form-control" :placeholder="$t('walkway_searchBy')" id="walkways_searchInput" />
                                                    <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                        <span><i class="la la-search"></i></span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="tab-content">
                                                <div class="tab-pane active">
                                                    <div id="walkwaysListWithScrollbar" v-if="filteredWalkways.length > 0" class="kt-scroll" data-scroll="true" data-height="600">
                                                        <div class="kt-widget4" id="walkwaysList">
                                                            <!-- Items will goes there -->
                                                            <div v-for="(walkway, index) in filteredWalkways" :key="walkway.id" class="kt-widget4__item walkwayLine" :class="index === filteredWalkways.length - 1 ? 'kt-margin-b-30' : ''">
                                                                <div @click="onHighlight" :data-walkwayid="walkway.id" v-bind:class="getHighlightListClass(walkway)" class="kt-widget4__info">
                                                                    <a @click="onHighlight" :data-walkwayid="walkway.id" href="#" class="highlightWalkwayLocationLink kt-widget4__username" v-bind:class="getHighlightClass(walkway)">
                                                                        {{ walkway.name }}
                                                                    </a>
                                                                    <p class="kt-widget4__text colorSubText">
                                                                        {{ walkway.distance }}
                                                                        <span v-if="walkway.distanceUnit === 'meters'">m</span>
                                                                        <span v-else>ft</span>
                                                                    </p>
                                                                    <p class="kt-widget4__text colorSubText">{{ walkway.building.name }} / {{ walkway.floor.name }} ({{ $t("b&f_level") }}: {{ walkway.floor.floorNumber }})</p>
                                                                </div>

                                                                <template v-if="currentWalkway.id === walkway.id && currentWalkway.state == 'EDIT'">
                                                                    <a @click="onCancelEditWalkway" :data-walkwayid="walkway.id" href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('gtw_cancelAction')" class="cancelEditWalkwayLocationButton walkwayListLink btn btn-sm btn-outline-dark btn-bold"> {{ $t("common_cancel") }} </a>
                                                                    <a @click="onSaveEditWalkway" :data-walkwayid="walkway.id" href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('walkway_saveEditWalkway')" class="saveEditWalkwayLocationButton btn btn-sm btn-outline-dark btn-bold"> <i class="la la-save editedAreaIcon"></i> {{ $t("common_save") }} </a>
                                                                </template>

                                                                <template v-if="(currentWalkway.id === '' || currentWalkway.state === 'HIGHLIGHT') && currentWalkway.state !== 'START_CREATION' && currentWalkway.state !== 'START_MEASURE'">
                                                                    <a @click="onHighlight" :data-walkwayid="walkway.id" href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('walkway_highlightWalkway')" class="highlightWalkwayLocationButton walkwayListLink btn btn-sm btn-outline-success btn-bold">
                                                                        <i class="la la-crosshairs walkwayListIcon"></i>
                                                                    </a>
                                                                    <div
                                                                        class="editWalkwayDropdown btn-
                                                                    group dropleft show"
                                                                        data-toggle="kt-tooltip"
                                                                        :data-original-title="$t('common_edit')"
                                                                        data-placement="top"
                                                                    >
                                                                        <a href="#" class="editWalkwayDropdownButton walkwayListLink btn btn-sm btn-outline-warning btn-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                            <i class="la la-pencil walkwayListIcon"></i>
                                                                        </a>
                                                                        <!--<div class="dropdown-menu" x-placement="left-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-184px, 0px, 0px);">-->
                                                                        <div class="dropdown-menu">
                                                                            <a @click="onEditWalkwayInfos" :data-walkwayid="walkway.id" class="editWalkwayInfosItem dropdown-item" href="#">{{ $t("walkway_editInformations") }}</a>
                                                                            <a v-if="!currentSite.isInProduction" @click="onEditWalkway" :data-walkwayid="walkway.id" class="editWalkwayPolygonItem dropdown-item" href="#">{{ $t("walkway_editPolyline") }}</a>
                                                                            <a v-else :data-walkwayid="walkway.id" class="editWalkwayPolygonItem dropdown-item" href="#" data-toggle="kt-tooltip" :data-original-title="$t('common_unauthorizedAction')" data-placement="top">
                                                                                <del> {{ $t("walkway_editPolyline") }} </del>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <a v-if="!currentSite.isInProduction" @click="onDeleteWalkway" :data-walkwayid="walkway.id" :data-walkwayname="walkway.name" href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('walkway_removeWalkway')" class="removeWalkwayButton btn btn-sm btn-outline-danger btn-bold">
                                                                        <i class="la la-remove walkwayListIcon"></i>
                                                                    </a>
                                                                    <button v-else data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_unauthorizedAction')" class="disabled removeWalkwayButton removeWalkwayLocationDisabledButton btn btn-sm btn-outline-danger btn-bold">
                                                                        <i class="la la-remove walkwayListIcon"></i>
                                                                    </button>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                                <div class="col-lg-7 col-xl-8">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet kt-portlet--tab">
                                        <div class="kt-portlet__body padding2">
                                            <div class="kt-section kt-margin-b-0">
                                                <!--begin: map -->
                                                <app-locationmap mode="walkwaysDefinition"></app-locationmap>
                                                <!--end: map -->
                                            </div>
                                        </div>
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                                <!-- end:: Content -->
                            </div>
                        </div>
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                </div>
            </div>
        </div>
        <app-walkwaypositionmodal></app-walkwaypositionmodal>
        <app-walkwaymodal></app-walkwaymodal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
import EmptyList from "./../utils/emptylist.vue";
import SiteTitle from "../site/widgets/sitetitle.vue";
import LocationMap from "../map/locationmap.vue";
import WalkwayModal from "../modals/walkwaymodal.vue";
import WalkwayPositionModal from "../modals/walkwaypositionmodal.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import commonVueHelper from '../../helpers/commonVueHelper';
import L from "leaflet"; // eslint-disable-line no-unused-vars

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteWalkwaysTable: null,
            isEmptyList: false,
            isCreation: true,
            localWalkways: [],
            searchTextValue: "",
            totalDistance: 0,
            isMounted: false,
            siteInProduction: false // using for emptylist element prop
        };
    },
    created: function() {
        console.log("Component(Walkways)::created() - called");
    },
    mounted: function() {
        console.log("Component(Walkways)::mounted() - Init metronic layout");
        this.isMounted = true;
        $(window).on("resize", () => {
            this.updateScrollbar();
        });
        this.updateScrollbar();
    },
    destroyed: function() {
        console.log("Component(Walkways)::destroyed() - called");
        this.resetWalkwaysState();
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --

        siteWalkways(areas) {
            console.log("Component(Walkways)::watch(siteWalkways) - called with : ", areas);
            if (this.siteWalkways) {
                this.localWalkways = this.siteWalkways;
                if (this.siteWalkways.length == 0) {
                    this.isEmptyList = true;
                } else {
                    this.isEmptyList = false;
                    this.calculateTotalWalkways();
                }
            }
        },

        buildingsAndTheirFloorsArray: function(arrayBF) {
            console.log("Component(Walkways)::watch(buildingsAndTheirFloorsArray) - called with : ", arrayBF);
            this.getSiteWalkways(this.siteId);
        },

        currentWalkway() {
            // Scroll only if highlight comes from the click on a marker inside the map
            if (this.currentWalkway.state === "HIGHLIGHT" && this.currentWalkway.isHighlightFromMap) {
                var element = $(".highlightWalkwayLocationLink[data-walkwayid='" + this.currentWalkway.id + "']");
                $("#walkwaysListWithScrollbar").scrollTop(0);
                $("#walkwaysListWithScrollbar").animate({scrollTop: element.offset().top - 350}, 600);
            }
        },

        currentSite(site) {
            console.log("Component(Walkways)::watch(currentSite)");
            if (site) {
                this.siteInProduction = site.isInProduction;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["siteWalkways", "buildingsAndTheirFloorsArray", "getBuildingById", "getFloorByBuildingIdAndFloorId", "currentWalkway", "getWalkwayById", "currentSite"]),

        // Computed property used to filter
        filteredWalkways: function() {
            // Init tooltips when render will be finished
            this.$nextTick(() => {
                this.updateScrollbar();
                $('[data-toggle="kt-tooltip"]')
                    .tooltip({ trigger: "hover" })
                    .on("click", function() {
                        $(this).tooltip("hide");
                    });
            });
            var self = this;
            if (!self.searchTextValue) {
                return this.localWalkways;
            } else {
                let filtered = this.localWalkways.filter(function(walkway) {
                    return walkway.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1 || (walkway.building && walkway.building.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1) || (walkway.floor && walkway.floor.name.toLowerCase().indexOf(self.searchTextValue.toLowerCase()) != -1);
                });
                return filtered;
            }
        },

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["setCurrentWalkway", "getSiteWalkways", "showDeleteModal", "resetWalkwaysState", "getSiteBuildingsAndTheirFloors"]),

        updateScrollbar() {
            let walkwaysListScrollbar = $("#walkwaysListWithScrollbar");
            let offset = 185;
            if (walkwaysListScrollbar) {
                // timeout is used to be sure to get the current map height
                setTimeout(() => {
                    let mapHeight = $("#map").height();
                    walkwaysListScrollbar.data("height", mapHeight > offset * 2 ? mapHeight - offset : offset);
                    walkwaysListScrollbar.attr("data-height", $("#map").height() - offset);
                    KTApp.init();
                    KTLayout.init();
                }, 100);
            }
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightClass(walkway) {
            return {
                cursorDefault: !walkway.geometry || !walkway.geometry.type,
                highlightedName: this.currentWalkway.id === walkway.id,
                colorMainText: this.currentWalkway.id !== walkway.id
            };
        },

        // This method is called to get list of classes that should be defined to highlight element into the list
        getHighlightListClass(walkway) {
            return {
                cursorPointer: walkway.geometry && walkway.geometry.type,
                highlightedItemList: this.currentWalkway.id === walkway.id
            };
        },

        onCreate() {
            // small timer is needed for autoscroll over map to work correctly
            setTimeout(() => {
                // Scroll on leaflet map
                if ($("#map").offset().top > $(window).height()) {
                    // only if map is not currently visible on screen, autoscroll
                    document.getElementById("map").scrollIntoView();
                }

                this.setCurrentWalkway({ id: "", state: "START_CREATION" });
            }, 1);
        },

        onCancelCreation() {
            console.log("Component(Walkways)::onCancelCreate - Cancel button clicked.");
            if (this.currentWalkway.state === "START_MEASURE") {
                this.setCurrentWalkway({ id: "", state: "CANCEL_MEASURE" });
            } else {
                this.setCurrentWalkway({ id: "", state: "CANCEL_CREATION" });
            }
            // Renitialisation of tooltip
            commonVueHelper.initToolTips();
        },

        onHighlight(event) {
            let target = event.currentTarget;
            let walkwayId = $(target).attr("data-walkwayid");
            console.log("Component(Walkways)::onHighlight - Highlight button clicked. walkwayId = " + walkwayId);
            this.setCurrentWalkway({ id: walkwayId, state: "HIGHLIGHT" });
        },

        onEditWalkway(event) {
            let target = event.currentTarget;
            let walkwayId = $(target).attr("data-walkwayid");
            console.log("Component(Walkways)::onEditWalkway - Edit polygon clicked. walkwayId = " + walkwayId);
            this.setCurrentWalkway({ id: walkwayId, state: "EDIT" });
        },

        onEditWalkwayInfos(event) {
            let target = event.currentTarget;
            let walkwayId = $(target).attr("data-walkwayid");
            console.log("Component(Walkways)::onEditWalkwayInfos - Edit infos clicked. walkwayId = " + walkwayId);

            // Get last version of walkway
            let walkway = this.getWalkwayById(walkwayId);

            var walkwayData = {
                id: walkway.id,
                name: walkway.name,
                color: walkway.color,
                siteId: walkway.site,
                buildingId: walkway.building.id,
                floorId: walkway.floor.id,
                geometry: walkway.geometry,
                distance: walkway.distance,
                distanceUnit: walkway.distanceUnit,
                state: "FINALIZE_EDITION"
            };
            // Will open confirmation pop-up from walkwayModal.vue component
            this.setCurrentWalkway(walkwayData);
        },

        // This method is called when user clicks on "Cancel" button after edit one walkway
        onCancelEditWalkway(event) {
            let target = event.currentTarget;
            let walkwayId = $(target).attr("data-walkwayid");
            console.log("Component(Walkways)::onCancelEditWalkway - walkwayId = " + walkwayId);
            this.setCurrentWalkway({ id: walkwayId, state: "CANCEL_EDIT" });
            // Renitialisation of tooltip
            commonVueHelper.initToolTips();
        },

        // This method is called when user click ton "Save" button after edit location of one gateway
        onSaveEditWalkway(event) {
            let target = event.currentTarget;
            let walkwayId = $(target).attr("data-walkwayid");
            console.log("Component(Walkways)::onSaveEditWalkway - walkwayId = " + walkwayId);
            this.setCurrentWalkway({ id: walkwayId, state: "SAVE_EDIT" });
        },

        onDeleteWalkway(event) {
            console.log("Component(Walkways)::onDeleteWalkway - Delete button clicked. walkwayId = " + walkwayId);
            let target = event.currentTarget;
            let walkwayId = $(target).attr("data-walkwayid");
            let walkwayName = $(target).attr("data-walkwayname");
            this.showDeleteModal({ textContent: i18n.t("walkway_deleteWalkwayConfirmText", { walkwayName: walkwayName }), actionName: "DELETE_WALKWAY", params: { siteId: this.siteId, walkwayId: walkwayId } });
        },

        onClickNeedHelp() {
            $("#walkwayPositionModal").modal("show");
        },

        calculateTotalWalkways() {
            console.log("Component(Walkways)::calculateTotalWalkways - called ");
            let totalDistance = 0;
            for (var walkway of this.siteWalkways) {
                totalDistance += walkway.distance;
            }
            this.totalDistance = Math.round(totalDistance * 1000) / 1000;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-emptylist": EmptyList,
        "app-sitetitle": SiteTitle,
        "app-locationmap": LocationMap,
        "app-walkwaymodal": WalkwayModal,
        "app-walkwaypositionmodal": WalkwayPositionModal,
        "app-accesserrorportlet": AccessErrorPortlet
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.padding2 {
    padding: 2px;
    z-index: 1;
}
.walkwayListIcon {
    margin-top: -2px;
    margin-right: -11px;
    margin-left: -6px;
}
.walkwayListLink {
    margin-right: 3px;
}
.alreadyLocatedLink {
    margin-right: 3px;
}
.editedAreaIcon {
    margin-top: -4px;
}
.colorMainText {
    color: #5c7cf7 !important;
}
.colorSubText {
    color: #5a6077 !important;
}
.cursorPointer {
    cursor: pointer;
}
.font500 {
    font-weight: 500;
}
.list-top-badge {
    margin-top: -10px;
}
.counterTopList {
    font-size: 12px;
    padding: 10px 10px 10px 10px;
    width: fit-content;
    background: #009688 !important;
}
.walkwayLine a:last-child {
    margin-right: 8px;
}

.helpButtonContainer {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.removeWalkwayLocationDisabledButton {
    margin-right: 8px;
}
</style>

<style>
.leaflet-tooltip-left:before {
    display: none;
}
</style>
