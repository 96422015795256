<template>
    <div class="modal fade show" id="walkwayModal" tabindex="-1" role="dialog" data-backdrop="static" aria-labelledby="exampleModalLabel" aria-modal="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form id="walkwayForm" class="kt-form" novalidate="novalidate" @submit.prevent="onSubmit">
                    <div class="modal-header">
                        <h5 v-if="isCreation" class="modal-title">{{ $t("walkway_walkwayCreation") }}</h5>
                        <h5 v-else class="modal-title">{{ $t("walkway_walkwayUpdate") }}</h5>
                        <button type="button" @click="cancelWalkway" class="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group validated">
                            <label>{{ $t("walkway_walkwayName") }} *</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="la la-pencil"></i></span>
                                </div>
                                <input id="walkwayNameInput" type="text" class="form-control" :placeholder="$t('walkway_inputInfoWalkwayName')" v-model="walkwayName" />
                            </div>
                            <div v-if="!$v.walkwayName.required" class="invalid-feedback">
                                {{ $t("error_fieldIsRequired") }}
                            </div>
                            <div v-else-if="!$v.walkwayName.minLen" class="invalid-feedback">
                                {{ $t("error_minLengthMsg", [$v.walkwayName.$params.minLen.min]) }}
                            </div>
                            <span class="form-text text-muted">{{ $t("walkway_detailInfoWalkwayName") }}</span>
                        </div>
                        <div class="form-group">
                            <label>{{ $t("walkway_color") }}</label>
                            <div class="input-group">
                                <input id="walkwayColorInput" class="form-control" type="color" v-model="walkwayColor" />
                            </div>
                            <span class="form-text text-muted">{{ $t("walkway_detailInfoWalkwayColor") }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="walkwayModalCancelButton" type="button" class="btn btn-secondary" @click="cancelWalkway" data-dismiss="modal">{{ $t("common_cancel") }}</button>
                        <button v-if="isCreation" id="walkwayModalSubmitButton" type="button" @click="submitWalkway" data-dismiss="modal" class="btn btn-brand trigger-submit" :disabled="$v.$invalid">{{ $t("common_create") }}</button>
                        <button v-else id="walkwayModalSubmitButton" type="button" @click="submitWalkway" data-dismiss="modal" class="btn btn-brand trigger-submit" :disabled="$v.$invalid">{{ $t("common_update") }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";
import commonVueHelper from '../../helpers/commonVueHelper';
export default {
    data() {
        return {
            walkwayName: "",
            walkwayColor: "#3388ff",
            isCreation: false,
            isEditPolygon: false
        };
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentWalkway: function(walkway) {
            console.log("Component(walkwayModal)::watch(currentWalkway) - called with : ", walkway);
            if (walkway.state == "FINALIZE_CREATION" || walkway.state == "FINALIZE_EDITION") {
                this.isCreation = walkway.state == "FINALIZE_CREATION" ? true : false;
                this.walkwayName = walkway.name;
                this.walkwayColor = "#" + walkway.color;
                $("#walkwayModal").modal("show");
            } else {
                this.isEditPolygon = walkway.state == "EDIT" ? true : false;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentWalkway"])
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        walkwayName: {
            required,
            minLen: minLength(2)
        }
    },
    methods: {
        ...mapActions(["createWalkway", "updateWalkway", "updateWalkwayPolygon", "setCurrentWalkway", "getWalkwayById"]),
        submitWalkway() {
            console.log("Component(walkwayModal)::submitWalkway() - called");
            let walkwayData = this.currentWalkway;
            walkwayData.name = this.walkwayName;
            walkwayData.color = this.walkwayColor.substring(1); // remove '#' character
            if (this.isCreation) {
                this.createWalkway(walkwayData);
            } else if (this.isEditPolygon) {
                this.updateWalkwayPolygon(walkwayData);
            } else {
                this.updateWalkway(walkwayData);
            }
            $("#walkwayModal").modal("hide");

            // Reset current walkway in store
            this.setCurrentWalkway({ id: "", state: "" });
        },
        cancelWalkway() {
            console.log("Component(walkwayModal)::cancelWalkway() - called");
            if (this.currentWalkway.state === "FINALIZE_CREATION") {
                this.setCurrentWalkway({ id: "", state: "" });
            } else if (this.currentWalkway.state === "FINALIZE_EDITION") {
                this.setCurrentWalkway({ id: "", state: "CANCEL_EDIT" });
                // Renitialisation of tooltip
                commonVueHelper.initToolTips();
            } else {
                this.setCurrentWalkway({ id: "", state: "" });
            }
        },
        onSubmit() {
            console.log("Component(walkwayModal)::onSubmit() - called");
            if (!this.$v.$invalid) {
                this.submitWalkway();
            }
        }
    }
};
</script>

<style scoped></style>
